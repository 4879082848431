var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            gap: "20px",
            width: "100%"
          }
        }, [_c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")]), _c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: function click($event) {
              return _vm.showAddModal(1);
            }
          }
        }, [_vm._v("添加")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.tableColumns,
      rowKey: "id",
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "isstatus",
      fn: function fn(isstatus) {
        return [_c("span", {
          style: {
            color: isstatus ? "#f57575" : "#64c136"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatStatus(isstatus)) + " ")])];
      }
    }, {
      key: "memo",
      fn: function fn(memo) {
        return [_c("span", [_vm._v(" " + _vm._s("".concat(memo ? memo : "---")) + " ")])];
      }
    }, {
      key: "action",
      fn: function fn(action, item) {
        return [_c("div", {
          staticClass: "btn-action-wrap"
        }, [_c("a-button", {
          attrs: {
            ghost: "",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.showAddModal(2, item);
            }
          }
        }, [_vm._v("编辑")]), item.isstatus == 1 ? _c("a-button", {
          staticStyle: {
            color: "#67c23a",
            "border-color": "#67c23a"
          },
          attrs: {
            ghost: ""
          },
          on: {
            click: function click($event) {
              return _vm.updateHotwordsStatus(item.id, 0);
            }
          }
        }, [_vm._v("恢复")]) : _c("a-button", {
          attrs: {
            ghost: "",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.updateHotwordsStatus(item.id, 1);
            }
          }
        }, [_vm._v("删除")]), _c("a-button", {
          on: {
            click: function click($event) {
              return _vm.getHotwordLogList(item.id);
            }
          }
        }, [_vm._v("日志")])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: _vm.modaltitle,
      width: 600,
      footer: null,
      centered: ""
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", _vm._b({
    ref: "dynamicValidateForm",
    attrs: {
      model: _vm.dynamicValidateForm
    }
  }, "a-form-model", _vm.layout, false), [_vm._l(_vm.dynamicValidateForm.keywords, function (item, index) {
    return _c("a-form-model-item", {
      key: item.key,
      attrs: {
        label: index === 0 ? "推荐词" : " ",
        prop: "keywords." + index + ".value",
        rules: {
          required: true,
          message: "推荐词不能为空",
          trigger: "blur"
        }
      }
    }, [_c("div", {
      staticClass: "form-limit-input",
      attrs: {
        "data-tip": "".concat(_vm.getLenOfKeyword(item.value), "/150")
      }
    }, [_c("a-input", {
      attrs: {
        type: "textarea",
        "auto-size": {
          minRows: 1,
          maxRows: 4
        },
        "max-length": 150,
        placeholder: "请输入推荐关键词"
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1), _vm.dynamicValidateForm.keywords.length > 1 ? _c("a-icon", {
      staticClass: "dynamic-delete-button",
      attrs: {
        type: "minus-circle-o",
        disabled: _vm.dynamicValidateForm.keywords.length === 1
      },
      on: {
        click: function click($event) {
          return _vm.removeKeyword(item);
        }
      }
    }) : _vm._e()], 1);
  }), _c("a-form-model-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("div", {
    staticClass: "form-limit-input form-input-memo",
    attrs: {
      "data-tip": "".concat(_vm.dynamicValidateForm.memo.length, "/120")
    }
  }, [_c("a-input", {
    attrs: {
      "max-length": 120,
      placeholder: "请输入备注"
    },
    model: {
      value: _vm.dynamicValidateForm.memo,
      callback: function callback($$v) {
        _vm.$set(_vm.dynamicValidateForm, "memo", $$v);
      },
      expression: "dynamicValidateForm.memo"
    }
  })], 1)]), _vm.dynamicValidateForm.keywords.length < 10 && _vm.modalttype == 1 ? _c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_c("a-button", {
    staticStyle: {
      "max-width": "262px"
    },
    attrs: {
      block: "",
      type: "dashed"
    },
    on: {
      click: _vm.addKeyword
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 添加推荐词 ")], 1)], 1) : _vm._e(), _c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit",
      loading: _vm.loading2
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("dynamicValidateForm");
      }
    }
  }, [_vm._v(" 确定 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    on: {
      click: function click($event) {
        return _vm.resetForm("dynamicValidateForm");
      }
    }
  }, [_vm._v(" 取消 ")])], 1)], 2)], 1), _c("a-modal", {
    attrs: {
      title: "操作日志",
      width: 1000,
      footer: null,
      centered: ""
    },
    model: {
      value: _vm.visible2,
      callback: function callback($$v) {
        _vm.visible2 = $$v;
      },
      expression: "visible2"
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource2,
      columns: _vm.tableColumns2,
      rowKey: "id",
      scroll: {
        x: "100%",
        y: 500
      },
      loading: _vm.loading3
    }
  }), _c("pagination", {
    attrs: {
      total: _vm.total2,
      page: _vm.page2,
      size: _vm.size2,
      onPageSizeChange: _vm.onPageSizeChange2,
      onShowSizeChange: _vm.onPageSizeChange2
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page2 = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size2 = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };