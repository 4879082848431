import { POST, GET } from "@/utils/util";
var ToolsApi = {
  // 获取热搜词管理列表
  getHotwordsList: function getHotwordsList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/hotwords/findpage", params);
  },
  // 修改热搜词状态
  updateHotwordsStatus: function updateHotwordsStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/hotwords/updatestatus", params);
  },
  // 批量添加热搜词
  addHotwords: function addHotwords() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/hotwords/batchsave", params);
  },
  // 修改热搜词
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/hotwords/update", params);
  },
  // 热搜词日志列表
  getHotwordLogList: function getHotwordLogList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/hotwords/gethotwordlog", params);
  }
};
export default ToolsApi;